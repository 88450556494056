import { isEqual } from 'lodash';
import { getAuthCookieDecoded } from 'utils/auth';
import dates from 'utils/date';
import { isDateISOString } from './dates';

class ConditionalFunctions {
	/**
	 * Method for verify value is a object or is a select object
	 * @param {any} val
	 * @param {boolean} selectValue
	 * @returns {boolean}
	 */
	static isObjectValue(val, selectValue) {
		const isObject = val instanceof Object && !Array.isArray(val);

		const isSelectValue = isObject && 'value' in val && 'label' in val;

		return selectValue ? isSelectValue : isObject;
	}

	/**
	 * Method helper for check if values is empty or not
	 * @param {any} value
	 * @param {boolean} multipleFields
	 * @param {boolean} notEmpty
	 * @returns {boolean}
	 */
	static emptyHelper(value, multipleFields, notEmpty) {
		const helperFuction = val => {
			if (Array.isArray(val)) return !val.length;
			return val === 0 ? false : !val;
		};

		if (multipleFields) {
			const values = Object.keys(value).map(key => {
				const resultValue = helperFuction(value[key]);
				return notEmpty ? !resultValue : resultValue;
			});

			return values.every(val => val);
		}

		const resultValue = helperFuction(value);

		return notEmpty ? !resultValue : resultValue;
	}

	/**
	 * Metbod for check if value is empty
	 * @param {any} value
	 * @param {boolean} multipleFields
	 * @returns {boolean}
	 */
	static isEmpty(value, multipleFields) {
		return this.emptyHelper(value, multipleFields);
	}

	/**
	 * Metbod for check if value is not empty
	 * @param {any} value
	 * @param {boolean} multipleFields
	 * @returns {boolean}
	 */
	static isNotEmpty(value, multipleFields) {
		return this.emptyHelper(value, multipleFields, true);
	}

	/**
	 * Metbod for check if value is equal to other value
	 * @param {any} value
	 * @param {boolean} multipleFields
	 * @returns {boolean}
	 */
	static isEqualTo(value, multipleFields, referenceValue) {
		const refValue = this.isObjectValue(referenceValue, true)
			? referenceValue.value
			: referenceValue;

		const isEqualTo = val => {
			// check select values
			if (this.isObjectValue(val, true)) return val.value === refValue;

			// check simple object
			if (this.isObjectValue(val)) return isEqual(val, refValue);

			// check other values
			return val === refValue;
		};

		if (multipleFields) {
			const notEmptyValues = Object.keys(value).map(key => isEqualTo(value[key]));
			return notEmptyValues.every(val => val);
		}

		return isEqualTo(value);
	}

	/**
	 * Metbod for check if value is not equal to other value
	 * @param {any} value
	 * @param {boolean} multipleFields
	 * @returns {boolean}
	 */
	static isNotEqualTo(value, multipleFields, referenceValue) {
		return !this.isEqualTo(value, multipleFields, referenceValue);
	}

	/**
	 * Method to check if a value is equal to some element of referenceValue
	 * @param {any} value
	 * @param {array} referenceValue
	 * @returns {boolean}
	 */
	static isOneOf(value, multipleFields, referenceValue) {
		const referenceArrayValue = Array.isArray(referenceValue) ? referenceValue : [referenceValue];

		return referenceArrayValue.some(itemReference =>
			this.isEqualTo(value, multipleFields, itemReference)
		);
	}

	/**
	 * Method to check if a value is not equal to some element of referenceValue
	 * @param {any} value
	 * @param {array} referenceValue
	 * @returns {boolean}
	 */
	static isNotOneOf(value, multipleFields, referenceValue) {
		return !this.isOneOf(value, multipleFields, referenceValue);
	}

	/**
	 * Method to check if the user is dev
	 */
	static isDev() {
		const { isDev = false } = getAuthCookieDecoded() || {};
		return isDev;
	}

	/**
	 * Method to check if the user is not dev
	 */
	static isNotDev() {
		return !this.isDev();
	}

	static isDate(value, referenceValue) {
		return isDateISOString(value) && isDateISOString(referenceValue);
	}

	static compare(value, referenceValue, operator = '') {
		const compareDates = this.isDate(value, referenceValue);

		const firstDate = compareDates ? dates.getUnix(value) : value;
		const secondDate = compareDates ? dates.getUnix(referenceValue) : referenceValue;

		switch (operator) {
			case '>=':
				return firstDate >= secondDate;
			case '<=':
				return firstDate <= secondDate;
			case '>':
				return firstDate > secondDate;
			case '<':
				return firstDate < secondDate;
			default:
				return false;
		}
	}

	/**
	 * Method to check if a value is greater than some element of referenceValue
	 */
	static greaterThan(value, multipleFields, referenceValue) {
		return this.compare(value, referenceValue, '>');
	}

	/**
	 * Method to check if a value is greater or equal than some element of referenceValue
	 */
	static greaterOrEqualThan(value, multipleFields, referenceValue) {
		return this.compare(value, referenceValue, '>=');
	}

	/**
	 * Method to check if a value is lower than some element of referenceValue
	 */
	static lowerThan(value, multipleFields, referenceValue) {
		return this.compare(value, referenceValue, '<');
	}

	/**
	 * Method to check if a value is lower or equal than some element of referenceValue
	 */
	static lowerOrEqualThan(value, multipleFields, referenceValue) {
		return this.compare(value, referenceValue, '<=');
	}

	/**
	 * Method to check if a value match a regex expression
	 */
	static matchesRegex(value, multipleFields, referenceValue) {
		const regexExpression = referenceValue;

		const testRegex = val => {
			// check select values
			if (this.isObjectValue(val, true)) return regexExpression.test(val.value);

			// check simple object
			if (this.isObjectValue(val)) return regexExpression.test(val);

			// check other values
			return regexExpression.test(val);
		};

		if (multipleFields) {
			const notEmptyValues = Object.keys(value).map(key => testRegex(value[key]));
			return notEmptyValues.every(val => regexExpression.test(val));
		}

		return testRegex(value);
	}

	/**
	 * Method to check if a value doesn't match a regex expression
	 */
	static notMatchesRegex(value, multipleFields, referenceValue) {
		return !this.matchRegex(value, multipleFields, referenceValue);
	}

	static parseValueToNumber(value) {
		return Number.parseFloat(value);
	}

	static isEqualToNumber(value, multipleFields, referenceValue) {
		const refValue = this.isObjectValue(referenceValue, true)
			? referenceValue.value
			: referenceValue;

		const isEqualTo = val => {
			// check select values
			if (this.isObjectValue(val, true))
				return this.parseValueToNumber(val.value) === this.parseValueToNumber(refValue);

			// check other values
			return this.parseValueToNumber(val) === this.parseValueToNumber(refValue);
		};

		if (multipleFields) {
			const notEmptyValues = Object.keys(value).map(key => isEqualTo(value[key]));
			return notEmptyValues.every(val => val);
		}

		return isEqualTo(value);
	}

	static isNotEqualToNumber(value, multipleFields, referenceValue) {
		return !this.isEqualToNumber(value, multipleFields, referenceValue);
	}

	static includes(value, field, referenceValue) {
		const refValue = this.isObjectValue(referenceValue, true)
			? referenceValue.value
			: referenceValue;

		if (typeof value === 'string') return value.includes(refValue);
		if (Array.isArray(value)) return value.some(item => item === refValue);

		return false;
	}
}

export default ConditionalFunctions;
